import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "sortOrder": 2.2,
  "menuLabel": "Tailwind Setup"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Tailwind Setup`}</h1>
    <p>{`Caesars UI plays very nice with Tailwind, and the component library uses Tailwind soley for styling.  The following information will help you get started using Tailwind in your project.`}</p>
    <h2>{`Tailwind Installation`}</h2>
    <p>{`If your current repo does not currently have Tailwind installed, follow these steps.`}</p>
    <h3>{`Tailwind`}</h3>
    <p>{`Install the following depedencies via NPM:`}</p>
    <p><inlineCode parentName="p">{`npm i -D tailwindcss postcss autoprefixer`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`You should try to use the latest PostCSS and TailwindCSS`}</p>
    </blockquote>
    <p>{`Setup a `}<strong parentName="p">{`tailwind.config.js`}</strong></p>
    <p><inlineCode parentName="p">{`npx tailwindcss init`}</inlineCode></p>
    <p>{`Add glob patterns for files to the content section where Tailwind should look for it's classes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{html,js,jsx,ts,tsx}', 
    // This tells Tailwind at everything in the ./src directory, anything with 
    // the file extension html/js/jsx/ts/tsx should be considered.
    './hooks/**/*.{js,jsx,ts,tsx}',
    './scripts/**/*.{js,jsx,ts,tsx}',
    './config/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {},
  },
  plugins: [],
};
`}</code></pre>
    <h3>{`PostCSS`}</h3>
    <p>{`We need to setup a `}<strong parentName="p">{`postcss.config.js`}</strong>{` in the root directory of the project if one doesn't already exist, and add the following to it: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// postcss.config.js
module.exports = {
  plugins: {
    tailwindcss: {},
    autoprefixer: {},
  }
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`There might be other setups if you have a postcss.config.js already, but essentially Tailwindcss and autoprefixer need to be setup in the plugins section.`}</p>
    </blockquote>
    <h3>{`Integrating with CSS`}</h3>
    <p>{`Create CSS File or introduce Tailwind PostCSS Directives into your current main CSS file at the top:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* ./src/styles/tailwind.css (could be index.css, or main.css, name doesn't matter) */
@tailwind base;
@tailwind components;
@tailwind utilities;
`}</code></pre>
    <p>{`Make sure this CSS file is being imported in your project either in the HTML, or if using a framework such as React, in the entry point file (e.g. main.js, index.js, etc).`}</p>
    <h2>{`Using Tailwind with Caesars UI`}</h2>
    <p>{`To use Caesars UI exclusively in Tailwind, we need to override some of the defaults Tailwind comes with.  We've exported JS modules that conveniently wrap our token definitions in a way that that's easily usable with Tailwind.`}</p>
    <h3>{`Bring in CSS token definitions`}</h3>
    <p>{`We need to make sure that the core and semantic token definition files are being imported into the project, along with the component library css file.  This can be done many places, but it probably makes sense to include it inside your framework as seperate imports.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import '@caesars-digital/caesars-ui/dist/tokens/css/caesars-ui-tokens.css'; // Core / Semantic tokens
import '@caesars-digital/caesars-ui/dist/css/caesars-ui.css'; // Component library CSS
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Refer to the `}<a parentName="p" {...{
          "href": "/getting-started/getting-started-for-developers/quick-start"
        }}>{`Quick Start`}</a>{` guide on installing `}<inlineCode parentName="p">{`caesars-ui`}</inlineCode></p>
    </blockquote>
    <h3>{`Overriding Tailwind defaults`}</h3>
    <p>{`Inside the `}<inlineCode parentName="p">{`tailwind.config.js`}</inlineCode>{` file, you can import new token definitions right from the `}<inlineCode parentName="p">{`caesars-ui`}</inlineCode>{` package.   These definitions are what help with themeing and are outlined in the `}<a parentName="p" {...{
        "href": "/tokens"
      }}>{`Token`}</a>{` section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/** @type {import('tailwindcss').Config} */

const core = require("@caesars-digital/caesars-ui/dist/tokens/tailwind/core");
const czrSemantic = require("@caesars-digital/caesars-ui/dist/tokens/tailwind/czr-semantic");
const mergeTokens = require("@caesars-digital/caesars-ui/dist/utils/cjs/mergeTokens").default

const mergedTokens = mergeTokens(core, czrSemantic);
module.exports = {
  content: [
    './src/**/*.{html,js,jsx,ts,tsx}',
    './hooks/**/*.{js,jsx,ts,tsx}',
    './scripts/**/*.{js,jsx,ts,tsx}',
    './config/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    ...mergedTokens,
    extend: {
      // custom extends here
    },
  },
  plugins: [],
};

`}</code></pre>
    <h3>{`Conclusion`}</h3>
    <p>{`You now have Tailwind setup in your project using Caesar UI tokens!  This allows you to do things such as `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- HTML --> 
<body class="czr-light">
    <div class="tw-bg-bg-default tw-text-fg-default">This is default text with a default background from Caesars UI</div>
</body>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Please be aware, a theme class needs to be applied somewhere in the heiarchy (preferably top most level such as `}<inlineCode parentName="p">{`<body>`}</inlineCode>{`) to see the stylings being applied.`}</p>
    </blockquote>
    <p>{`All Tailwind classes follow a similar convention `}<inlineCode parentName="p">{`{custom prefix}-{tailwind-class-name}-{token-name}`}</inlineCode>{` so for example, if we want to use the `}<inlineCode parentName="p">{`highlight-brand`}</inlineCode>{` token as a background color we can do the following: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tw-bg-highlight-brand">...text</div>
`}</code></pre>
    <p>{`If we have the custom prefix `}<inlineCode parentName="p">{`tw-`}</inlineCode>{` that would go first.  Then we use the Tailwind class name, in this case `}<inlineCode parentName="p">{`bg`}</inlineCode>{` for background, and then we use our own token name, `}<inlineCode parentName="p">{`highlight-brand`}</inlineCode>{`.   This convention can be used throughout the apply our tokens to Tailwind utility classes.  `}</p>
    <p>{`To use padding where a Caesars UI spacing token is named `}<inlineCode parentName="p">{`4`}</inlineCode>{`, would look like `}<inlineCode parentName="p">{`tw-p-4`}</inlineCode>{` (16px of padding).`}</p>
    <blockquote>
      <p parentName="blockquote">{`A full list of Tailwind utilities can be found here `}<a parentName="p" {...{
          "href": "https://tailwindcss.com/docs/utility-first"
        }}>{`Tailwind Docs`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      